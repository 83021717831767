@font-family-material: 'Material Icons';
@font-family-material-outline: 'Material Icons Outlined';

.material(@material-icon-content) {
	font-family: @font-family-material;
	content: @material-icon-content;
	-webkit-font-feature-settings: 'liga' 1;
	-moz-font-feature-settings: 'liga' 1;
	font-feature-settings: 'liga' 1;
}

.material-outline(@material-icon-content) {
	font-family: @font-family-material-outline;
	content: @material-icon-content;
	-webkit-font-feature-settings: 'liga' 1;
	-moz-font-feature-settings: 'liga' 1;
	font-feature-settings: 'liga' 1;
}

.material-link-arrow() {
	.material-outline(@link-arrow-icon);
	font-weight: @font-weight;
	font-size: @link-arrow-font-size;
	position: relative;
	top: 8px;
	padding-left: 20px;
}

.material-skinny-arrow() {
	background-image: url(/resources/img/icons/71/long-arrow-forward.svg);
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 50%;
	padding-right: 50px;
	content: "";
	height: 20px;
}