@import (reference) "../../mixins/_styleResets.less";
@import (reference) "../../mixins/_icons.less";

.image-and-detail {
	display: flex;
	flex-direction: column;
	
	@media (min-width: @screen-tablet) {
		flex-direction: row;
		align-items: center;
		gap: 68px;
		
		& > * {
			flex: 1;
		}
	}
}

.image-and-detail--inverse {
	@media (min-width: @screen-tablet) {
		flex-direction: row-reverse;
	}
}

.image-and-detail__image-container {
	width: 100%;
	height: 100%;
	order: 2;
	
	@media (min-width: @screen-tablet) {
		position: relative;
		max-width: 550px;
		order: revert;
	}
}

.image-and-detail__image {
	width: 100%;
	height: 100%;
	
	@media (max-width: @screen-mobile-max) {
		margin-left: 10px;
	}
	
	@media (min-width: @screen-tablet) {
		/*	position: absolute;
			margin-left: -32px;*/
	}
}

.image-and-detail--inverse {
	.image-and-detail__image {
		@media (min-width: @screen-tablet) {
			//margin-left: 16px;
		}
	}
}

.image-and-detail__content {
	//color: @color-gray-darker;
	text-align: center;
	margin-bottom: 30px;
	@media (min-width: @screen-tablet) {
		text-align: left;
	}
}

.image-and-detail__content li {
	font-size: 16px;
	padding-bottom: 20px;
	line-height: 27px;
	@media (min-width: @screen-laptop) {
		font-size: 18px;
	}
}

.image-and-detail__title {
	.font-h2();
	margin-bottom: 30px;
}

.image-and-detail__bold {
	font-weight: @font-weight-semibold;
}

.image-and-detail__description {
	font-size: 18px;
	line-height: 22px;
}

.image-and-detail__subheader {
	margin-bottom: 30px;
}
