@import (reference) "../_variables.less";
@import (reference) "../../global/global-template.less";

#sign-up-button, .cta-sign-up-button {
	color: @color-white;
	display: flex;
	align-items: center;
	font-size: @font-size-xl;
	font-weight: @font-weight-bold;
	line-height: 20px;
	width: fit-content;
	padding: @spacing-paragraph @spacing-standard;
	background-color: @color-blue-primary;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	min-height: revert;
	white-space: nowrap;
	
	&:hover {
		background-color: @color-brand-light;
	}
}
#sign-up-button {
	@media(max-width: @screen-mobile-max){
		width: 100%;
		justify-content: center;
	}
}
a.cta-sign-up-button.button {
	color: @color-white;
	background-color: @color-blue-primary;
	&:hover {
		background-color: @color-brand-light;
	}
}

nav.breadcrumbs {
	background-color: @color-white;
}

header.hero.with-cta {
	section.content {
		display: flex;
		align-items: center;
		
		@media (max-width: (@screen-laptop - 1px)) {
			flex-direction: column;
			row-gap: @spacing-standard;
			
			.hero-cta {
				flex: auto;
				margin-right: 0;
				width: 100%;
			}
		}
	}
	
	h1 {
		text-align: left;
		flex: 1 1 100%;
	}
}

.hero-cta {
	flex: 0 0 430px;
	text-align: left;
	border-radius: 6px;
	border: 1px solid @color-highlight;
	background-color: @color-white;
	padding: @spacing-standard;
	margin-right: @spacing-standard;
}

.hero-cta__title {
	font-size: 24px;
	line-height: 31px;
	font-weight: @font-weight-bold;
	font-family: @font-family-header;
	color: @color-brand;
}

.hero-cta__prompt {
	font-size: 16px;
	line-height: 22.4px;
	font-family: @font-family-header;
	padding-top: @spacing-paragraph;
}

.hero-cta__select {
	width: 100%;
	font-size: 16px;
}

.hero-cta__button {
	padding-top: @spacing-paragraph;
}

.hero-cta__create-account {
	display: inline-block;
	background-color: @color-brand;
	color: @color-white;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	font-size: 24px;
	line-height: 33.6px;
	font-family: @font-family-header;
	font-weight: @font-weight-bold;
	padding: 13px @spacing-large;
}

.cta__create-account {
	display: inline-block;
	background-color: @color-brand;
	color: @color-white;
	font-weight: @font-weight-bold;
	font-size: 24px;
	font-family: @font-family-header;
	padding: 18px @spacing-standard;
	border-radius: @spacing-standard;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	
	@media (max-width: @screen-mobile-max) {
		width: 100%;
		text-align: center;
	}
	
	&:hover {
		background-color: @color-brand-light;
		text-decoration: none;
	}
}

.cta-footer {
	background-color: rgba(255, 176, 135, 0.5);
	padding: 45px @spacing-large;
	margin: 0;
	
	@media (max-width: @screen-tablet) {
		padding: @spacing-standard;
	}
	
	.cta__create-account {
		&:hover {
			background-color: @color-button;
		}
	}
	
}

.testimonial-cta {
	.purple-hr {
		display: none;
		@media (min-width: @screen-laptop) {
			display: block;
			border-top: 1px solid @color-brand-light;
			margin: 0 0 5px;
		}
	}
}

.cta-video-lessons-small {
	
	h2 {
		text-align: center;
	}
	
	.cta-button-container {
		text-align: center;
		margin-top: 30px;
		
		@media (min-width: @screen-laptop) {
			margin-top: 60px;
		}
	}
	
	.cta-sign-up-button {
		margin-left: auto;
		margin-right: auto;
		font-size: @font-size-cta;
	}
}

.pill-button {
	.pill-button();
	margin-bottom: 0;
}



.visible-mobile (){
	@media (max-width: @screen-xs-max) {
		display: block;
	}
	display: none;
}
.hidden-mobile (@display: block){
	display: none;
	@media(min-width: @screen-mobile){
		display: @display;
	}
}
.stats-section__cards {
	@media(min-width: @screen-tablet){
		flex-wrap: wrap;
	}
}

.full-width-cta {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	background-color: @color-bleed-background;
}
.white-bleed {
	padding-top: 30px;
	padding-bottom: 30px;
	@media(min-width: @screen-tablet){
		padding-top: 90px;
		padding-bottom: 90px;
	}
	&:not(:first-child){
		margin-top: 30px;
		@media(min-width: @screen-tablet){
			margin-top: 80px;
		}
	}
	&:not(:last-child){
		margin-bottom: 30px;
		@media(min-width: @screen-tablet){
			margin-bottom: 80px;
		}
	}
	
	.content--cta {
		padding-top: 0;
		padding-bottom: 0;
	}
	.pill-button {
		margin-bottom: 0;
	}
}
.content--cta{
	padding: 120px 30px 40px;
	max-width: 1060px;
	margin-left: auto;
	margin-right: auto;
	.logo {
		@media(max-width: @screen-mobile-max){
			display: none;
		}
		margin-bottom: 5px;
	}
}


.image-and-detail {
	font-family: @font-family-header;
	color:@color-gray-3;
	font-size: 18px;
	a.button {
		background:@color-blue-primary;
		border-color: @color-blue-primary;
		border-radius: 0;
		display: block;
		@media(min-width: @screen-mobile){
			display: inline-block;
		}
		&.hidden-mobile{.hidden-mobile(@display: inline-block)}
		&.visible-mobile{.visible-mobile()}
		
		
	}
	
	.bullet {
		@media(min-width: @screen-mobile){
			::marker {
				color:@color-blue-primary;
			}
		}
	}
}
.image-and-detail__title{
	color: #000;
	@media(min-width:@screen-tablet) {
		font-size: 30px;
	}
}
.image-and-detail__content li {
	font-size: 18px;
	padding-bottom: 0;
	text-align: left;
	@media(min-width:@screen-tablet) {
		padding-top:8px;
		padding-bottom:8px;
	}
	
}

.stats-section__cards {
	@media (max-width: @screen-mobile-max) {
		display: block;
		
		.stat-card {
			background: none;
			box-shadow: none;
			padding: 2px 0;
			display: flex;
			flex-direction: row;
			gap: 5px;
			
			&:before {
				content: "\2022";
				font-size: 12px;
				color: #000;
			}
		}
		.stat-card__text {
			font-size: 18px
		}
		.stat-card__image-container {
			display: none;
		}
		
		.stat-card__detail {
			flex-direction: row;
			text-align: left;
			color: #000;
		}
		
		.stat-card__number {
			font-size: 18px;
			line-height: 25px;
			margin-right: 5px;
		}
	}
}
.stat-card__detail {
	color: #000;
}
.stat-card:hover {
	@media(min-width: @screen-tablet){
		box-shadow: 0 0 13px -2px #333;
	}
	.stat-card__number {
		color: #000;
	}
}

.stat-card__number {
	font-weight: @font-weight-header-bold;
}
.white-bleed.image-and-detail__bleed-tablet .image-and-detail__image-container {
	align-self: end;
	
	.image-and-detail {
		@media (min-width: @screen-tablet) {
			margin-bottom: 0;
		}
		@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max){
			flex-direction: column;
		}
	}
	.image-and-detail__image {
		@media(max-width: @screen-mobile-max){
			display: none;
		}
		position: absolute;
		bottom: -90px;
		left: -100px;
		width: auto;
		height: auto;
	}
}
.study-own-pace,.video-lessons {
	.image-and-detail__image-container {
		@media (max-width: @screen-mobile-max) {
			display: none;
		}
	}
}
