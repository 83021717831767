@import url(@font-import);
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");

* {
	box-sizing: border-box;
}

html {
	text-rendering: optimizeLegibility;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	-webkit-font-smoothing: antialiased !important;
}

h1, .h1 {
	.font-h1();
}

h2, .h2 {
	.font-h2();
}

h3, .h3 {
	.font-h3();
}

body {
	.font-body();
	margin: 0;
}

.body {
	.font-body();
}

a {
	cursor: pointer;
	.font-link();

	&.no-style {
		text-decoration: none !important;
		color: @color-text;
		font-weight: @font-weight;
	}
}

h1, h2, h3, h4, h5, h6, ul, ol, table, .as-h {
	margin: 0;

	& + p, & + .as-p {
		margin-top: @spacing-paragraph;
	}

	p + &, .as-p + &, & + & {
		margin-top: @spacing-standard;
	}

	&.lg-space {
		margin-top: 60px;
	}
}

ol, ul {
	& + p, & + .as-p {
		margin-top: @spacing-standard;
	}
}

p, .as-p {
	margin: 0;
	& + & {
		margin-top: @spacing-paragraph;
	}

	& + label, label + & {
		margin-top: @spacing-standard;

	}
}

label {
	display: block;
}

header, main {
	padding: @spacing-standard 0;

	@media (min-width: @screen-tablet) {
		padding: @spacing-large 0;
	}
}

html, body {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}

main {
	background: @color-white;
}

figure {
	margin: 0;
}

select.index-dropdown {
	border: 1px solid @color-gray-border;
	border-radius: 100px;
	padding: 15px 20px;
	font-size: 14px;
	color: @color-gray-select;
}
.table-of-contents-bar {
	select.index-dropdown {
		width: 100%;
		max-width: 500px;
	}
}

.width-and-gutters() {
	max-width: @max-width-content + @spacing-standard * 2;
	
	padding-left: @spacing-standard;
	padding-right: @spacing-standard;
	
	@media (min-width: @screen-max-content) {
		margin: auto;
	}
}

section.content,
section >.content {
	position: relative;
	.width-and-gutters();
	
	&:not(:last-of-type) {
		padding-bottom: @spacing-standard;

		@media (min-width: @screen-tablet) {
			padding-bottom: @spacing-large;
		}
	}

	&:not(:first-of-type) {
		padding-top: @spacing-standard;

		@media (min-width: @screen-tablet) {
			padding-top: @spacing-large;
		}
	}
}

header.hero {
	.hero-bg();
	text-align: center;
	position: relative;
	
	@media (min-width: @screen-tablet) {
		text-align: left;
	}
	
	@media(max-width: @screen-mobile-max) {
		label {
			width: 100%;
		}
	}
}

.center {
	text-align: center;
}

button {
	cursor: pointer;
}