@import (reference) "../../mixins/_styleResets.less";
@import (reference) "src/main/webapp/less/mixins/_icons.less";

.stats-section {
	padding-top: 45px;
	padding-bottom: 70px;
	
	@media (min-width: @screen-tablet) {
		padding-top: 90px;
		padding-bottom: 130px;
	}
}

.stats-section__container {
	margin: 0 auto;
	//max-width: @max-section-content-width;
}

.stats-section__title {
	//margin: 0 0 50px;
	//font-size: 28px;
	//font-weight: @font-weight;
	//line-height: 38px;
	//text-align: center;
	
	//@media (min-width: @screen-tablet) {
	//	margin: 0 0 45px;
	//	font-size: 36px;
	//	line-height: 50px;
	//}
}

.stats-section__cards {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 15px;
	padding: 0 15px;
	flex-wrap: wrap;
	
	& > * {
		flex: 1 1 40%;
	}
	
	@media (min-width: @screen-tablet) {
		flex-wrap: nowrap;
		
	}
}
.stat-card {
	display: flex;
	align-items: center;
	gap: 30px;
	padding: 18px 22px;
	box-shadow: 0px 0px 13px -2px @color-gray-3;
	border-radius: 6px;
	overflow: hidden;
	flex-direction: column;
	background: white;
	&:hover {
		box-shadow: 0 0 0 2px @color-brand;
		text-decoration: none;
		
		.stat-card__number {
			color: @color-brand;
		}
	}
	
	@media (min-width: @screen-tablet) {
		flex-direction: column;
		gap: 0;
		padding: 48px 0;
	}
}

.stat-card__image-container {
	margin-bottom: 0;
	width: 56px;
	
	@media (min-width: @screen-tablet) {
		margin-bottom: 30px;
		width: unset;
	}
}

.stat-card__image {
	width: 100%;
	height: 100%;
}

.stat-card__detail {
	display: flex;
	flex-direction: column;
	color: @color-gray-5;
	text-align: center;
}

.stat-card__number {
	font-size: 26px;
	font-weight: @font-weight-bold;
	line-height: 36px;
}

.stat-card__text {
	font-size: 16px;
	font-weight: @font-weight-semibold;
	line-height: 25px;
	
	@media(min-width: @screen-tablet){
		font-size: 18px;
		font-weight: 700;
	}
}
