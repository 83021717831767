@import (reference) "../../mixins/_styleResets";

.table-of-contents__list {
	margin-top: 0;
	column-gap: @spacing-standard;
	column-count: 1;
	font-size: 14px;
	color: @color-link;
	break-inside: avoid;

	li {
		break-inside: avoid;
	}

	@media (min-width: @screen-mobile) {
		column-count: 2;
	}

	@media (min-width: @screen-tablet) {
		column-count: 3;
	}
}