@import (reference) "../../mixins/_styleResets";
@import (reference) "../../mixins/_icons";


.make-breadcrumbs(@background-color, @color: @color-link) {
	nav.breadcrumbs {
		.font-body();
		background-color: @background-color;
		font-weight: @font-weight-bold;
		color: @color;
		font-size: 13px;
		letter-spacing: @letter-spacing-ssp;
		padding: @spacing-paragraph @spacing-standard;

		ul {
			.plain-ul();
			max-width: @max-width-hero;
			margin: auto;
			position: relative;
			
			@media (max-width: @screen-xl) {
				padding-left: 10px;
			}

			li {
				display: inline;
				font-weight: @font-weight-bold;
				font-family: @font-family-header;
			}

			> :first-child:before {
				.material(@material-arrow-right);
				color: @color;
				vertical-align: middle;
				
				@media (max-width: @screen-xl) {
					left: -3px;
					position: absolute;
				}
			}

			> :not(:last-child):after {
				content: " / "
			}
		}
	}
}