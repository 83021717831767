.plain-ul() {
	list-style-type: none;
	padding: 0;
	margin-left: 0;

	li {
		padding: 0;
		margin: 0;
	}
}

.plain-button() {
	margin: 0;
	padding: 0;
	width: revert;
	height: revert;
	background-color: revert;
	
	button:hover {
		background-color: revert;
	}
}

.pill-button(@color-brand: #F48120,@font-size: 18px) {
	font-size: @font-size;
	background: @color-brand;
	color:#fff;
	padding: 20px 40px;
	border-radius: 50px;
	display: inline-block;
	margin-top: 30px;
	margin-bottom:30px;
	line-height: 1;
	box-shadow: none;
	&:hover {
		text-decoration: none;
	}
}

.gradient (@startColor: #fdc936, @endColor: #d49c01) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);
	background: -o-linear-gradient(top, @startColor, @endColor);
}