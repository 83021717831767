@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";

footer {
	background-color: @color-brand;
	padding: 60px;
	text-align: center;

	.footer-domain {
		color: white;
	}

	ul {
		.plain-ul;
		text-align: center;

		a {
			color: white !important;
		}

		@media (min-width: @screen-tablet) {
			li {
				display: inline;

				&:not(:last-child):after {
					content: " | ";
					color: white;
				}
			}
		}
	}

	.copyright {
		margin-top: @spacing-large;
		color: white;
		font-size: 13px;
	}
}