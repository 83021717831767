@import (reference) "_variables";
@import (reference) "../_functions";
@import (reference) "../mixins/_styleResets";
@import (reference) "../mixins/_icons";
@import (reference) "../global/components/top-breadcrumbs";

@import "../global/global-template";

@import "components/_topNav";
@import "components/_footer.less";
@import "components/_ctas.less";
@import "../global/components/_image-and-details.less";
@import "../global/components/_statsSection.less";

body {
	scroll-behavior: smooth;
}

main {
	overflow-wrap: break-word;
	padding-bottom: 90px;
}

.hero {
	p {
		font-size: 24px;

		@media(min-width: @screen-tablet) {
			font-size: 30px;
		}
		color: @color-gray-3;
		line-height: @line-height-pct;
	}
}

.make-breadcrumbs(transparent);

h4 {
	font-size: 18px;

	@media(min-width: @screen-tablet) {
		font-size: 24px;
	}

	&.section-title {
		font-weight: 600;
	}

	line-height: @line-height-pct;
}

section.blue-bleed {
	background-color: @color-blue-gray;
	padding-top: @spacing-standard;
	padding-bottom: @spacing-standard;

	@media(min-width: @screen-tablet) {
		padding-top: @spacing-large;
		padding-bottom: @spacing-large;
	}
}

button, input[type=button], a.button {
	font-size: 22px;
	font-weight: @font-weight-bold;
	font-family: "Open Sans", sans-serif;
	text-align: center;
	vertical-align: center;
	line-height: @line-height-pct;
	text-decoration: none;
	color: white;

	background-color: @color-button;

	border: none;

	border-radius: 25px;
	min-height: 50px;

	@media(min-width: @screen-tablet) {
		border-radius: 30px;
		min-height: 60px;
	}

	max-width: 315px;
	padding: @spacing-paragraph @spacing-paragraph;

	position: relative;
	cursor: pointer;

	&:active, &:hover {
		background-color: @color-button-active;
		color: @color-link;
	}

	&.body-btn {
		min-width: 315px;
	}

	&.btn-circle-arrow {
		height: 40px;
		width: 40px;
		border-radius: 20px;
		padding: 0;
		min-height: 40px;

		&:after {
			.material("arrow_forward");
			vertical-align: bottom;
		}
	}
}

.pull-quote {
	font-size: 28px;
	line-height: @line-height-pct;
}

.quote-block {
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto;
	max-width: 800px;
}

.testimonial-text {
	font-size: 24px;
	font-family: "Open Sans", sans-serif;
	color: @color-blue-1;

	@media (min-width: @screen-tablet) {
		font-size: 28px;
	}
	line-height: @line-height-pct;
}

.testimonial-name {
	font-size: 16px;

	@media (min-width: @screen-tablet) {
		font-size: 18px;
	}

	padding-left: @spacing-standard;

	color: @color-blue-1;
	font-family: "Open Sans", sans-serif;
	font-weight: @font-weight-bold;
}

select {
	.font-body();
	background-color: @color-white;
	border-radius: 0;
	padding: @spacing-minimum @spacing-paragraph;
	border: 1px solid @color-blue-1;

	option {
		.font-body();
	}
}

.hero-action {
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
	margin-top: @spacing-standard;
	padding: @spacing-standard;
	font-size: 18px;
	display: inline-block;
	max-width: ~"calc(min(100%, 460px))" !important;

	p {
		text-align: center;
		font-style: italic;
		color: @color-text;
		font-size: 18px;
	}

	@media(min-width: @screen-tablet) {
		margin-top: @spacing-large;
	}
}

ul.d-two-col {
	break-inside: avoid;
	page-break-inside: avoid;
	-webkit-column-break-inside: avoid;

	@media (min-width: @screen-tablet) {
		column-count: 2;
		column-gap: @spacing-standard;
		break-inside: avoid;
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
	}
}

.cta-divider {
	position:relative;
	background-color: @color-blue-5;

	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: "";
		z-index: 0;
		background-image: url("/resources/img/77/woman-at-desk-bw.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		mix-blend-mode: multiply;
	}

	padding: @spacing-standard;
	border-radius: 8px;
	display: flex;
	color: @color-brand;
	align-items: center;
	text-align: center;

	.dropdown-container {
		max-width: ~"calc(min(100%, 400px))";

		@media (min-width: @screen-tablet) {
			background-color: @color-white;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			border-radius: 8px;
		}

		@media (max-width: @screen-mobile-max) {
			padding: 0;
		}
	}



	p {
		flex: 1;
		z-index: 1;
	}

	label {
		flex: 0;
		padding: @spacing-paragraph @spacing-standard;
		border-radius: 8px;
		z-index: 1;
	}

	flex-direction: column;
	> *:not(:first-child) {
		margin-top: @spacing-standard;

		@media (min-width: @screen-tablet) {
			margin-top: 0;
			margin-left: @spacing-standard;
		}
	}

	> div {
		z-index: 1;
	}

	@media (min-width: @screen-tablet) {
		flex-direction: row;
		text-align: left;
	}
}

.action-dropdown {
	display: flex;
	max-width: ~"calc(min(100%, 400px))";

	select {
		flex: 1;
		max-width: ~"calc(100% - 50px)";
	}

	*:not(:first-child) {
		margin-left: @spacing-minimum;
	}
}

.link-rows {
	.plain-ul();
	border: 1px solid @color-gray-8;
	border-radius: 8px;
	padding: @spacing-standard;

	li {
		margin-bottom: @spacing-paragraph;
		position: relative;
		padding-left: 30px;
		break-inside: avoid;

		&:before {
			content: " ";
			position: absolute;
			width: 18px;
			height: 18px;
			background-image: url("/resources/img/77/icons/grading.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			top: 2px;
			left: 0;
		}
	}
}

.weight-n {
	font-weight: @font-weight;
}

.text-lg {
	font-size: @font-size-lg;
}

.c-black {
	color: @color-gray-3;
}

.c-white {
	color: white;
}

.c-brand {
	color: @color-brand;
}

.c-highlight {
	color: @color-highlight;
}

.margin-huge {
	margin-top: 90px !important;
}

p + ul {
	margin-top: @spacing-paragraph;
}

.body-btn {
	margin: auto;
	display: block;
}

table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 4px;

	@media (max-width: @screen-mobile-max) {
		display: block;

		&:not(.responsive-table) {
			overflow-x: scroll;

			tbody {
				display: table;
			}
		}

		&.responsive-table {
			border: none;

			tbody {
				display: block;
				border: 1px solid @color-brand-light;
				border-top: 15px solid @color-brand-light;
			}

			tr {
				display: block;
				padding-top: @spacing-paragraph;
				border: none;

				&:first-child {
					display: none;
				}

				&:nth-of-type(odd) {
					background-color: @color-gray-e;
				}

				&:nth-of-type(even) {
					background-color: @color-white;
				}
			}

			td {
				display: flex;
				column-gap: @spacing-paragraph;
				padding: 0 @spacing-paragraph @spacing-paragraph;
				align-items: center;

				border: none !important;

				&:before {
					content: attr(data-label);
					.font-body();
					color: @color-blue-2;
					font-weight: 600;
					text-transform: none;
					text-align: left;
					flex: 0 0 ~"calc(50% - 15px)";
				}
			}
		}
	}

	max-width: 100%;

	tbody {
		width: 100%;
	}

	border: 1px solid @color-blue-3;

	th {
		background-color: @color-blue-4;
		color: @color-blue-1;
	}

	tr:nth-of-type(even) {
		background-color: @color-gray-e;
	}

	td, th {
		padding: @spacing-paragraph @spacing-standard;

		&:not(:last-of-type) {
			border-right: 1px solid @color-gray-8;
		}
	}

	margin: auto;

	&.imageplugin {
		width: auto;

		td {
			padding: 5px;
		}
	}
}

@import "../global/components/faqs";

.faqs__faq__header {
	font-family: @font-family;
	font-weight: 600;
}

@import "../global/components/tableOfContents";

.table-of-contents {
	margin-bottom: @spacing-standard;
}

.table-of-contents__header {
	font-family: @font-family-header;
	font-weight: 700;
	margin-bottom: @spacing-paragraph;
	font-size: 16px;
	color: @color-gray-7;
}

.float-right {
	float: right;
}
