@import "../global/global-variables";

@color-blue-primary: #002868;
@color-blue-1: #1a3b66;
@color-blue-2: #035ea5;
@color-blue-3: #5eb4e7;
@color-blue-4: #A0DBE9;
@color-blue-5: #BDEBF6;
@color-blue-6: #DAF7FE;
@color-blue-gray: #CFEAF2;

@color-orange-1: #FC7E04;
@color-gold-1: #FFB900;
@color-gold-2: #FEF7D1;

@color-gray-f8: #F8F8F8;
@color-gray-e: #EEE;
@color-gray-8: #888;
@color-gray-7: #777;
@color-gray-5: #555555;
@color-gray-4: #444;
@color-gray-3: #333;
@color-gray-2: #E1E1E1;
@color-gray-border: #D9D9D9;
@color-gray-select: #B6B6B6;
@color-brand: @color-blue-1;
@color-brand-light: @color-blue-3;
@color-text: @color-gray-3;
@color-link: @color-blue-2;
@color-highlight: @color-gold-1;
@color-button: @color-orange-1;
@color-button-active: @color-gold-1;
@color-practice-exam-success: #03A53A;
@color-bleed-background: #E0E2E5;
@color-white: #FFFFFF;

@screen-xs: 375px;
@screen-xs-max: 575px;
@screen-mobile: 576px;
@screen-mobile-max: (768px - 1);
@screen-tablet: 768px;
@screen-tablet-max: (@screen-laptop - 1);
@screen-laptop: 992px;
@screen-max-content: (@max-width-content + @spacing-standard * 2);
@screen-max-hero: (@max-width-hero + @spacing-standard * 2);
@screen-xl: 1200px;
@screen-desktop: @screen-xl;
@screen-xxl: 1400px;

@spacing-large: 45px;
@spacing-standard: 30px;
@spacing-paragraph: 15px;
@spacing-minimum: 10px;

@font-import: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,400;0,600&family=Oswald:wght@500&display=swap';

@font-family: 'Lato', sans-serif;
@font-family-header: 'Open Sans', sans-serif;

@font-weight-header-bold: 700;
@font-weight-bold: 600;
@font-weight: 400;

@font-size: 16px;
@font-size-lg: 18px;
@font-size-sm: 14px;
@font-size-xs: 12px;
@font-size-xl: 20px;
@font-size-tiny: 13px;
@font-size-cta: 24px;
@font-size-larger-not-huge: 30px;

@max-width-content: 1000px;
@max-width-hero: 1200px;

@letter-spacing-ssp: 0.1px;
@line-height-pct: 140%;

.font-body() {
	font-family: @font-family;
	font-weight: @font-weight;
	font-size: @font-size;
	line-height: @line-height-pct;
	color: @color-text;
}

.font-hero() {
	.font-body();
	font-size: @font-size;
}

.font-h3() {
	.font-body();
	color: @color-brand;
	font-family: @font-family-header;

	font-size: 20px;

	@media (min-width: @screen-tablet) {
		font-size: 28px;
	}
}

.font-h2() {
	.font-h3();
	font-size: 22px;

	@media (min-width: @screen-tablet) {
		font-size: 32px;
	}

	font-weight: @font-weight-header-bold;
}

.font-h1() {
	.font-h2();

	color: @color-brand;
	font-size: 24px;

	@media (min-width: @screen-tablet) {
		font-size: 36px;
	}

	font-weight: @font-weight-header-bold;
}

.hero-bg() {
	background-color: @color-white;
}

.font-link() {
	color: @color-link;
	font-weight: @font-weight-bold;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

@material-expand-more: '\e5cf';
@material-expand-less: '\e5ce';
@material-arrow-right: '\e5df';
@material-arrow-forward: '\e5c8';

@color-error: #A94442;
@color-blue-3: #6A98B1;
@color-gray-1: #F8F8F8;
@color-gray-4: #777777;
@font-weight-semibold: 500;