@import (reference) "../../mixins/_styleResets";

.faqs {
	line-height: @line-height-pct;
	margin-top: @spacing-standard;
}

.faqs__header {
	text-align: left;
}

.faqs__list {
	font-size: 14px;

	.plain-ul();
	break-inside: avoid;

	@media (min-width: @screen-tablet) {
		column-count: 2;
		column-gap: @spacing-standard;
	}

	> li {
		margin-bottom: @spacing-standard;
	}
}

.faqs__faq {
	break-inside: avoid;
	margin-bottom: @spacing-standard;
}

.faqs__faq__header {
	margin-bottom: @spacing-paragraph;
	font-size: 14px;
}